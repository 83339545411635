<template>
  <div>
    <NuxtLoadingIndicator />

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<style>
  canvas {
    box-shadow: 0px 0px 5px 2px #ededed;
    margin: 0 auto;
    max-width: 100%;
  }
</style>
