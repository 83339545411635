import { default as _Ob7z14Y3UNMeta } from "/opt/buildhome/repo/pages/_.vue?macro=true";
import { default as indexbeJg92bIcgMeta } from "/opt/buildhome/repo/pages/app/billing/index.vue?macro=true";
import { default as upgradeWlv8QH8x3EMeta } from "/opt/buildhome/repo/pages/app/billing/upgrade.vue?macro=true";
import { default as confirmCZlLzOWnSwMeta } from "/opt/buildhome/repo/pages/app/confirm.vue?macro=true";
import { default as _91id_93mkBtHPefKrMeta } from "/opt/buildhome/repo/pages/app/documents/[id].vue?macro=true";
import { default as indexFOM2kVbY1gMeta } from "/opt/buildhome/repo/pages/app/documents/index.vue?macro=true";
import { default as trash0BtHZrkI1CMeta } from "/opt/buildhome/repo/pages/app/documents/trash.vue?macro=true";
import { default as loginGHbkpViGYsMeta } from "/opt/buildhome/repo/pages/app/login.vue?macro=true";
import { default as logoutaq90ULWtwuMeta } from "/opt/buildhome/repo/pages/app/logout.vue?macro=true";
import { default as onboardingNFhCFH79UgMeta } from "/opt/buildhome/repo/pages/app/onboarding.vue?macro=true";
import { default as indexD7aMTW6zNzMeta } from "/opt/buildhome/repo/pages/app/profile/index.vue?macro=true";
import { default as registerHPTG5fXWXpMeta } from "/opt/buildhome/repo/pages/app/register.vue?macro=true";
import { default as index0oYYumfx07Meta } from "/opt/buildhome/repo/pages/app/settings/index.vue?macro=true";
import { default as indexod8ZLTfbXsMeta } from "/opt/buildhome/repo/pages/app/support/index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as privacy69l1Gm1VSzMeta } from "/opt/buildhome/repo/pages/privacy.vue?macro=true";
import { default as termsjw1825O2ZdMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
import { default as component_45stubGZr1UkTkg3Meta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubGZr1UkTkg3 } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "_",
    path: "/_",
    component: () => import("/opt/buildhome/repo/pages/_.vue")
  },
  {
    name: "app-billing",
    path: "/app/billing",
    meta: indexbeJg92bIcgMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/billing/index.vue")
  },
  {
    name: "app-billing-upgrade",
    path: "/app/billing/upgrade",
    meta: upgradeWlv8QH8x3EMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/billing/upgrade.vue")
  },
  {
    name: "app-confirm",
    path: "/app/confirm",
    component: () => import("/opt/buildhome/repo/pages/app/confirm.vue")
  },
  {
    name: "app-documents-id",
    path: "/app/documents/:id()",
    meta: _91id_93mkBtHPefKrMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/documents/[id].vue")
  },
  {
    name: "app-documents",
    path: "/app/documents",
    meta: indexFOM2kVbY1gMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/documents/index.vue")
  },
  {
    name: "app-documents-trash",
    path: "/app/documents/trash",
    meta: trash0BtHZrkI1CMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/documents/trash.vue")
  },
  {
    name: "app-login",
    path: "/app/login",
    meta: loginGHbkpViGYsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/login.vue")
  },
  {
    name: "app-logout",
    path: "/app/logout",
    component: () => import("/opt/buildhome/repo/pages/app/logout.vue")
  },
  {
    name: "app-onboarding",
    path: "/app/onboarding",
    component: () => import("/opt/buildhome/repo/pages/app/onboarding.vue")
  },
  {
    name: "app-profile",
    path: "/app/profile",
    meta: indexD7aMTW6zNzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/profile/index.vue")
  },
  {
    name: "app-register",
    path: "/app/register",
    meta: registerHPTG5fXWXpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/register.vue")
  },
  {
    name: "app-settings",
    path: "/app/settings",
    meta: index0oYYumfx07Meta || {},
    component: () => import("/opt/buildhome/repo/pages/app/settings/index.vue")
  },
  {
    name: "app-support",
    path: "/app/support",
    meta: indexod8ZLTfbXsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/support/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/opt/buildhome/repo/pages/privacy.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/opt/buildhome/repo/pages/terms.vue")
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/old-page",
    component: component_45stubGZr1UkTkg3
  }
]